<template>
    <div>
        <div class="page-header" :class="type == 'fixed' ? 'fixed' : ''">
            <div class="page-header-content page-box-layout clearfix" >
                <div class="logo fl">
                    <span v-if="$store.state.userInfo" class="menu-icon el-icon-s-fold hidden-sm-and-up" @click="drawer = true"></span>
                    <router-link to="/" >
                        <img src="@/assets/img/logo.png" />
                    </router-link>
                </div>
                <div class="fr">
                    <ul class="nav fl hidden-xs-only">
                        <li class="fl">
                            <router-link to="/index" :class="$route.path.match(new RegExp('index', 'g')) ? 'active' : ''">首页</router-link>
                        </li>
                        <li class="fl">
                            <router-link to="/manage" :class="$route.path.match(new RegExp('manage', 'g')) ? 'active' : ''">平台管理</router-link>
                        </li>
                        <li class="fl">
                            <a :href="`${ssweb}/apps/profession`" target="_blank">专业版下载</a>
                        </li>
                        <li class="fl">
                            <router-link to="/showstartRelease" :class="$route.path.match(new RegExp('showstartRelease', 'g')) ? 'active' : ''">秀动发行</router-link>
                        </li>
                        <li class="fl">
                            <router-link to="/ticketCode" :class="$route.path.match(new RegExp('ticketCode', 'g')) ? 'active' : ''">秀动票夹二维码下载</router-link>
                        </li>
                    </ul>
                    <div v-if="$store.state.userInfo" class="nav-right fl">
                        <!-- <el-badge :value="200" :max="99" class="message">
                            <router-link to="/">
                                <span class="el-icon-message-solid"></span>
                            </router-link>
                        </el-badge> -->
                        <el-dropdown @command="handleCommand">
                            <span class="el-dropdown-link">
                                <el-avatar :src="$store.state.userInfo.avatar"></el-avatar>
                                <span class="user-name">{{$store.state.userInfo.name}}</span>
                                <i class="el-icon-caret-bottom el-icon--right"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" :split-button="true">
                                <el-dropdown-item icon="el-icon-switch-button" command="logout">退出登录</el-dropdown-item>
								<el-dropdown-item icon="el-icon-edit-outline" command="editpwd">修改密码</el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>   
                    </div>
                    <div v-else class="no-login fl">
                        <el-button type="primary" round @click="$router.push({path:'/register'})">入驻平台</el-button>
                        <el-button plain round @click="$router.push({path:'/login'})">登录</el-button>
                    </div>
                </div>
            </div>
        </div>
        <el-drawer
            :visible.sync="drawer"
            :with-header="false"
            size="225px"
            custom-class="menu-drawer hidden-sm-and-up"
            ref="drawer"
            direction="ltr"
            append-to-body	
        >
            <el-aside style="width:auto">
                <page-menu :isCollapse="false" :menuWidth="225"></page-menu>
            </el-aside>
        </el-drawer>
    </div>
</template>
<script>
import PageMenu from "./menu";
export default {
    props:{
        type: {
            type: String,
            default: ''
        }
    },
    components: { PageMenu },
    data(){
        return{
            drawer: false,
            ssweb:`${process.env.VUE_APP_SSWEB_URL}`,
            // releaseWeb: `${process.env.VUE_APP_RELEASEWEB_URL}`
        }
    },
    methods:{
        handleCommand ( command ) {
            // 退出登录
            if( command === 'logout' ){
                this.$request({
                    url:'/user/login/logout'
                }).then(res => {
                    const{ state,msg } = res.data
                    if(state != 1) return this.$message.error(msg);
                    this.$store.commit('logOut')
                    this.$router.push({path:'/login'})
                })
				
            }
			if( command === 'editpwd' ){
				this.$router.push({path:'/manage/workbench/accountInfo'})
			}
            
        }
    }
}
</script>
<style lang="scss" scoped>
.page-header {
    background: #000000;
    height: $--header-height;
    &.fixed{
        position: fixed;
        width: 100%;
        z-index: 100;
        left: 0;
        top: 0;
    }
    .logo {
        padding: 25px 0;
        height: 70px;
        display: flex;
        align-items: center;
        .menu-icon{
            font-size: 30px;
            color: #ffffff;
            margin: 0 10px 0 0;
        }
    }
    .nav {
        font-size: 14px;
        a {
            color: rgba(255,255,255,0.5);
            height: $--header-height;
            line-height: $--header-height;
            margin-left: 40px;
            &:hover,&.active{
                color: #ffffff;
            }
        }
    }
    .nav-right{
        margin-left: 40px;
        .message{
            a{
                color: #ffffff;
                font-size: 20px;
            }
            ::v-deep .el-badge__content{
                border: 0;
            }
        }
        .el-dropdown-link{
            margin-left: 40px;
            height: $--header-height;
            line-height: $--header-height;
            color: #ffffff;
            display: flex;
            align-items: center;
            .el-avatar{
                width: 24px;
                height: 24px;
                vertical-align: middle;
                margin-right: 6px;
            }
            .user-name{
                max-width: 90px;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
    }
    .no-login {
        .el-button {
            height: 34px;
            line-height: 34px;
            padding: 0 20px;
            margin: 18px 0 0 40px;
            font-size: 13px;
            &.is-plain{
                background: none;
                color: #ffffff;
                border-color: #ffffff;
            }
        }
    }
}

::v-deep .menu-drawer {
    background: #161616;
    min-width: auto;
    .el-aside{
        height: 100vh;
    }
}
</style>