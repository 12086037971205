<template>
    <div class="page-menu">
        <el-menu
            :collapse="isCollapse"
            background-color="#161616"
            text-color="rgba(255,255,255,0.6)"
            active-text-color="#ffffff"
            unique-opened
            :style="`width:${menuWidth}px`"
            :collapse-transition="false"
            router
            :default-active="setMenuActive()"
        >
            <template v-for="item in menuList">
                <template v-if="item.subMenuList">
                    <el-submenu :index="`${item.id}`" :key="item.id" :show-timeout="1000">
                        <template slot="title">
                            <i class="iconfont" v-html="item.icon"></i>
                            <span slot="title">{{ item.name }}</span>
                        </template>
                        <el-menu-item
                            v-for="subItem in item.subMenuList"
                            :key="subItem.id"
                            :index="`${subItem.link}`"
                            >{{ subItem.name }}
                        </el-menu-item>
                    </el-submenu>
                </template>
                <template v-else>
                    <template v-if="item.link && item.link != 'showstartRelease'">
                        <el-menu-item  :index="`${item.link}`" :key="item.id">
                            <i class="iconfont" v-html="item.icon"></i>
                            <span slot="title">{{ item.name }}</span>
                        </el-menu-item>
                    </template>
                    <template v-else>
                        <el-menu-item @click="$release('/manage/dashboard')" :key="item.id">
                            <i class="iconfont" v-html="item.icon"></i>
                            <span slot="title">{{ item.name }}</span>
                        </el-menu-item>
                    </template>
                </template>
            </template>
        </el-menu>
    </div>
</template>
<script>
export default {
    name: "pageMenu",
    props: {
        menuWidth: {
            type: Number,
            default: 0,
        },
        isCollapse: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            menusIcon: {
                运营管理: "&#xe630;",
                演出管理: "&#xe62d;",
                众筹管理: "&#xe62f;",
                财务管理: "&#xe62e;",
				数据分析: "&#xe603;",
                发布音乐: "&#xe63a;",
                商品管理: "&#xe63b;",
                其他: "&#xe630;",
            },
        };
    },
    computed: {
        menuList() {
            let _menuList = [...this.$store.state.menuList] || [];	
            _menuList.push({
                link: 'showstartRelease',
                name: '发布音乐',
                id: 999999999
            })
            let _menus = [];
            _menuList.forEach((item) => {
                item.icon = this.menusIcon[item.name] || this.menusIcon["其他"];
                _menus.push(item);
            });
            return _menus;
        },
    },
    // 监听,当路由发生变化的时候执行
    watch: {
        $route() {
            this.setMenuActive();
        },
    },
    methods: {
        //设置菜单选择项
        setMenuActive() {
            let path = "/";
            this.menuList.forEach((menu) => {
                const { subMenuList } = menu;
                if (subMenuList && subMenuList.length > 0) {
                    subMenuList.forEach((item) => {
                        if (
                            item.link &&
                            this.$route.path.match(new RegExp(item.link, "g"))
                        ) {
                            path = item.link;
                        }
                    });
                } else {
                    if (
                        menu.link &&
                        this.$route.path.match(new RegExp(menu.link, "g"))
                    ) {
                        path = menu.link;
                    }
                }
            });
            return path;
        },
    },
};
</script>
<style lang="scss" scoped>
.page-menu{
    .el-menu {
        border: 0;
        .iconfont{
            margin-right: 10px;
            font-size: 14px;
        }
        .el-submenu {
            &.is-active{
                .iconfont{
                    color: $--color-theme;
                }
            }
            ::v-deep .el-menu{
                background: #000000 !important;
                .el-menu-item{
                    background: none !important;
                    &.is-active{
                        // background: $--color-theme !important;
                    }
                }
            }
        }
    }
}

</style>